@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap);
  
/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: #1b1523;
  color: #fff;
}

button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #c23866;
  color: #fff;
}
.card-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
}

@media (min-width: 768px) {
  .card-grid {
    grid-gap: 25px;
  }
}

.card-wrapper {
  position: relative;
  box-sizing: content-box;
}

.card {
  border: none;
  font-size: 18px;
  font-weight: bold;
  display: block;
  width: 58px;
  height: 58px;
  border-radius: 29px;
  background-color: transparent;
/*   border: 1px solid #fff; */
}

@media (min-width: 768px) {
  .card {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    font-size: 30px;
  }
}


/* front of card - the picture */
.card-wrapper .front {
  position: absolute;
  -webkit-transform: rotateY(90deg);
          transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  background-color: #198754;
  transform: rotateY(90deg);
}

.flipped .front {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  transition-delay: 0.2s;
}

/* back of card - the cover */
.card-wrapper .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2s;
  background-color: #dc3545;
}

.flipped .back {
  -webkit-transform: rotateY(90deg);
          transform: rotateY(90deg);
  transition-delay: 0s;
}

