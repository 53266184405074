.card-wrapper {
  position: relative;
  box-sizing: content-box;
}

.card {
  border: none;
  font-size: 18px;
  font-weight: bold;
  display: block;
  width: 58px;
  height: 58px;
  border-radius: 29px;
  background-color: transparent;
/*   border: 1px solid #fff; */
}

@media (min-width: 768px) {
  .card {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    font-size: 30px;
  }
}


/* front of card - the picture */
.card-wrapper .front {
  position: absolute;
  transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  background-color: #198754;
  transform: rotateY(90deg);
}

.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0.2s;
}

/* back of card - the cover */
.card-wrapper .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2s;
  background-color: #dc3545;
}

.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}
